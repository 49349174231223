<template>
  <div
    v-if="btn60Show"
    v-show="showNow"
    class="call-me d-flex align-center justify-center"
    :class="{ show: showBtnComputed }"
  >
    <button
      class="call-me__btn d-flex align-center"
      @click="openDialog"
    >
      <img
        src="@/assets/images/svg/phone-calling.svg"
        class="d-flex"
        alt="phone"
        :class="{ bzz: !textHide }"
      >
      <p
        class="call-me__text"
        :class="{ hide: textHide }"
      >
        <span>Перезвоним за 60 сек</span>
      </p>
    </button>
    <LazyVDialog
      v-model="showDialog"
      :fullscreen="!disabledPortal"
      :width="disabledPortal ? 'auto' : ''"
    >
      <LazyModalPartnersCallMe @close="showDialog = false" />
    </LazyVDialog>
  </div>
</template>

<script setup lang="ts">
import { useMainStore } from '~/store/main/main.store'
import { throttle } from '~/helpers'

const mainStore = useMainStore()
const showBtn = ref(false)
const textHide = ref(true)
const almostShown = ref(false)
const showDialog = ref(false)
const btn60Show = computed(() => mainStore.getBtn60Show)
const disabledPortal = computed(() => mainStore.disabledPortal)
const showBtnComputed = computed(() => !segmentationActive.value && showBtn.value)

const showNow = computed(() => mainStore.getCallSlots?.display_right_away)
const segmentationActive = computed(() => mainStore.getSegmentationActive)

const openDialog = () => {
  showDialog.value = true
}

let footer
const updateBlockVisibility = () => {
  if ((footer?.getBoundingClientRect().top) > window.innerHeight && window.scrollY > window.innerHeight / 2) {
    showBtn.value = true
    nextTick(() => {
      if (showBtn.value && !almostShown.value) {
        textHide.value = false
        setTimeout(() => {
          if (showBtn.value) {
            textHide.value = false
            almostShown.value = true
            setTimeout(() => {
              if (showBtn.value) {
                textHide.value = true
              }
            }, 10000)
          }
        }, 3000)
      }
    })
  }
  else {
    if (!textHide.value) {
      textHide.value = true
      setTimeout(() => {
        showBtn.value = false
      }, 240)
    }
    else {
      showBtn.value = false
    }
  }
}
const blockVisibilityDebounce = throttle(() => updateBlockVisibility(), 300)

onMounted(() => {
  footer = document.querySelector('.p-footer')
  window.addEventListener('scroll', blockVisibilityDebounce)
  blockVisibilityDebounce()
})
onBeforeUnmount(() => {
  window.removeEventListener('scroll', blockVisibilityDebounce)
})
</script>

<style scoped lang="scss">
.call-me {
  position: fixed;
  right: 70px;
  bottom: 70px;
  z-index: 5;
  transition: transform 0.24s;
  transform: scale(0);
  &:before {
    content: "";
    position: absolute;
    left: -16px;
    top: -16px;
    right: -16px;
    bottom: -16px;
    border-radius: 100px;
    background: rgba(color(white), 0.5);
  }
  @media (max-width: getBreakpoint(tablet)) {
    right: 18px;
    bottom: 30px;
  }
  &.show {
    transform: scale(1);
  }
  &__btn {
    border-radius: 40px;
    background: color(additional-light);
    color: color(white);
    position: relative;
    padding: 19px;
    @media (max-width: getBreakpoint(tablet)) {
      padding: 16px;
    }
    .d-icon {
      height: 32px;
      @media (max-width: getBreakpoint(tablet)) {
        height: 24px;
      }
      @media (max-width: getBreakpoint(mobile-lg)) {
        height: 22px;
      }
      @media (max-width: getBreakpoint(mobile-md)) {
        height: 20px;
      }
      &:deep(svg) {
        width: 32px;
        height: 32px;
        @media (max-width: getBreakpoint(tablet)) {
          width: 24px;
          height: 24px;
        }
        @media (max-width: getBreakpoint(mobile-lg)) {
          width: 22px;
          height: 22px;
        }
        @media (max-width: getBreakpoint(mobile-md)) {
          width: 20px;
          height: 20px;
        }
      }
      &.bzz {
        animation: shake 0.8s infinite;
      }
    }
    &:before {
      content: "";
      position: absolute;
      left: -12px;
      top: -12px;
      right: -12px;
      bottom: -12px;
      border-radius: 60px;
      border: 12px solid color(additional-light);
      opacity: 0.2;
      transition: opacity 0.24s;
    }
    &:after {
      content: "";
      position: absolute;
      top: -16px;
      left: -16px;
      right: -16px;
      bottom: -16px;
      border-radius: 80px;
      border: 4px solid color(additional-light);
      opacity: 0.1;
      transition: opacity 0.24s;
    }
    &:hover {
      &:before {
        opacity: 0.3;
      }
      &:after {
        opacity: 0.2;
      }
    }
  }
  @keyframes shake {
    0% {
      transform: rotate(-4deg);
    }
    10% {
      transform: rotate(4deg);
    }
    20% {
      transform: rotate(-4deg);
    }
    30% {
      transform: rotate(4deg);
    }
    40% {
      transform: rotate(-4deg);
    }
    50% {
      transform: rotate(4deg);
    }
    60% {
      transform: rotate(-4deg);
    }
    70% {
      transform: rotate(4deg);
    }
    80% {
      transform: rotate(-4deg);
    }
    90% {
      transform: rotate(4deg);
    }
    100% {
      transform: rotate(-4deg);
    }
  }

  &__text {
    overflow: hidden;
    max-width: 183px;
    transition: max-width 0.24s;
    white-space: nowrap;
    &.hide {
      max-width: 0;
    }
    span {
      display: inline-block;
      padding-left: 12px;
    }
  }
}
</style>
